import React, { useEffect, useState } from "react";
import { Message, Link } from "basis";
import { useQueryParam, StringParam } from "use-query-params";

import { jwtWatcher } from "../../../utils";
import AuthContext from "../../../core/AuthContext";
import { GATEWAY_NAME, GATEWAY_TITLE } from "../../../core/constants";
import {
  GatewaySettings,
  GatewayCredentialsUpdated,
  InfoCard,
  Scene,
} from "../../../layout/shopify";

import { ErrorMessage, ERROR_CODES } from "../../../components";

const Settings = () => {
  const [shop] = useQueryParam("shop", StringParam);
  const [gatewayName] = useQueryParam("gatewayName", StringParam);
  const [token] = useQueryParam("token", StringParam);

  const [paymentAppID, setPaymentAppID] = useState();
  const [errorName, setErrorName] = useState();

  // show empty page when params are invalid
  useEffect(() => {
    if (token) {
      jwtWatcher.subscribe({
        token,
        onExpiry: () => setErrorName(ERROR_CODES.TOKEN_EXPIRED),
      });
    }
  }, [token]);

  if (errorName) {
    return <ErrorMessage type={errorName}></ErrorMessage>;
  }

  if (
    !token ||
    !shop ||
    !Object.values(GATEWAY_NAME).find((val) => val === gatewayName)
  ) {
    return null;
  }

  return (
    <AuthContext.Provider value={token}>
      <Scene>
        {// success message and redirect back to shopify
        paymentAppID && (
          <GatewayCredentialsUpdated
            shop={shop}
            appID={paymentAppID}
            gatewayName={gatewayName}
          />
        )}
        {// accept credentials from user
        !paymentAppID && (
          <InfoCard
            title="Almost there!"
            description={`Last step before you can accept payments with ${GATEWAY_TITLE[gatewayName]}`}
            gatewayName={gatewayName}
          >
            <GatewaySettings
              shop={shop}
              gatewayName={gatewayName}
              onSuccess={({ appID }) => setPaymentAppID(appID)}
            />
            <Message severity="info-or-minor" bg="transparent">
              Verifying credentials will override previous credentials, if
              already exist.
            </Message>
          </InfoCard>
        )}
      </Scene>
    </AuthContext.Provider>
  );
};

export default Settings;
